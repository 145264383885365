import { List, Map } from 'immutable';
import { compact } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export function unique() {
    return uuidv4();
}

export function noop() {}

export function fire(actions, func, val) {
    return (e) => {
        const _func = actions[func];

        return (actions && func && typeof _func === 'function') ? _func(val || e) : null;
    };
}

export function click(func, val) {
    return (e) => (func && typeof func === 'function') ? func(val || typeof val !== 'undefined' ? val : e) : null;
}

export function clickPrevent(func, val) {
    return (e) => {
        e.preventDefault();
        e.stopPropagation();
        click(func, val)(e);
    };
}

export function imageLoaded(func, val) {
    return (e) => {
        if (func && typeof func === 'function') {
            func(val || typeof val !== 'undefined' ? val : e);
        }
    };
}

export function key(func, val) {
    return (e) => (func && typeof func === 'function') ? func(val || e) : null;
}

export function enter(func) {
    return (e) => {
        return (func && typeof func === 'function' && e.keyCode === 13) ? func() : null;
    };
}

export function escape(func) {
    return (keyCode) => {
        return (func && typeof func === 'function' && keyCode === 27) ? func() : null;
    };
}

export function state(func, key) {
    return (e) => (func && typeof func === 'function') ? func({ [key]: e.target.value }) : null;
}

export function input(func, val) {
    return (e) => (func && typeof func === 'function') ? func(val || e.target.value) : null;
}

export function ref(target) {
    return (e) => {
        this[target] = e;
    };
}

export function isLoading(fetch, status) {
    return status.getIn([fetch, 'loading']);
}

export function topPosition(element) {
    if (!element) {
        return 0;
    }

    return element.offsetTop + topPosition(element.offsetParent);
}

export function absolutePosition(element) {
    const bodyRect = document.body.getBoundingClientRect();
    const clientRect = element.getBoundingClientRect();

    return {
        top: clientRect.top - bodyRect.top,
        left: clientRect.left - bodyRect.top,
    };
}

export function intToList(int, properties = List()) {
    let list = List();

    for (let x = 1; x <= int; x++) {
        let obj = Map();

        if (properties && !properties.count()) {
            obj = x;
        } else {
            obj = properties.reduce((map, property) => {
                return map.set(property, x);
            }, Map());
        }

        list = list.push(obj);
    }

    return list;
}

export function isHome() {
    const path = compact(window.location.pathname.split('/'));

    return path.length === 1;
}

export function camelCase(str) {
    return str
        .replace(/\s(.)/g, (later) => later.toUpperCase())
        .replace(/\s/g, '')
        .replace(/^(.)/, (first) => first.toLowerCase());
}
