import React from 'react';

import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const SentryRoute = Sentry.withSentryRouting(Route);

const RouteWithSubRoutes = (props) => {
    const {
        component: Component,
        path,
        routes,
    } = props;

    function renderFunction(props) {
        return <Component {...props} routes={routes} />;
    }

    return (
        <SentryRoute
            path={path}
            // eslint-disable-next-line react/jsx-no-bind
            render={renderFunction}
        />
    );
};

RouteWithSubRoutes.propTypes = {
    component: PropTypes.func,
    path: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        exact: PropTypes.bool,
        component: PropTypes.func,
    })),
};

export default RouteWithSubRoutes;
